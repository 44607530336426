import { HiOutlineCircleStack, HiOutlineFolderOpen } from "react-icons/hi2";
import { AiOutlineLineChart } from "react-icons/ai";
import { GrLineChart } from "react-icons/gr";
import { IoShareSocialOutline } from "react-icons/io5";
import { GiMoneyStack } from "react-icons/gi";

export const menuAwal = [
  {
    link: "/home/dashboard",
    title: "Dashboard",
    icons: <HiOutlineCircleStack className="w-6 h-6 mx-2" />,
    role:['admin-lembaga','eksekutif','bendahara']
  },
];
export const menuRencana = [
  {
    link: "/home/kegiatan",
    title: "Kegiatan",
    icons: <HiOutlineFolderOpen className="w-6 h-6 mx-2" />,
    role: ["admin-lembaga", "bendahara"],
  },
  // {
  //   link: "/home/perencanaan-awal-tahun",
  //   title: "Perencanaan Awal Tahun",
  //   icons: <HiOutlineFolderOpen className="w-6 h-6 mx-2" />,
  //   role: ["admin-lembaga", "bendahara"],
  // },
  {
    link: "/home/pendapatan",
    title: "Pendapatan",
    icons: <AiOutlineLineChart className="w-6 h-6 mx-2" />,
    role: ["admin-lembaga", "bendahara"],
  },
  {
    link: "/home/program-bersama",
    title: "Program Bersama",
    icons: <IoShareSocialOutline className="w-6 h-6 mx-2" />,
    role: ["bendahara"],
  },
];

export const menuRealisasi = [
  {
    link: "/home/status-anggaran",
    title: "Status Anggaran",
    icons: <GrLineChart className="w-5 h-5 mx-3" />,
    role: ["eksekutif"],
  },
  {
    link: "/home/pencairan-anggaran",
    title: "Pencairan Anggaran",
    icons: <GiMoneyStack className="w-5 h-5 mx-3" />,
    role: ["admin-lembaga"],
  },
];
