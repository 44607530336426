import React, { useEffect, useState } from "react";
import HeaderUi from "../component/HeaderUi";
import useLembagaApakahOptions from "../options/lembaga";
import Selector from "../templates/Selector";
import Table from "../templates/Table";
import { useDispatch, useSelector } from "react-redux";
import { allRincianByLembaga as allRincianByLembaga } from "../store/actions/program";
import { currency } from "../component/Currency";
import Button from "../component/Button";
import FormPengajuan from "../templates/FormPengajuan";
import { postAntrian } from "../store/actions/antrian";
import SnackBar from "../component/Snackbar";
import Loader from "../component/Loader";
import { getAllSharingProgram } from "../store/actions/sharing";
import { convertToRupiah, formatRupiah } from "../component/FormatMoney";

export default function ApproveAnggaran() {
  const dispatch = useDispatch();
  const lembagaApakah = useLembagaApakahOptions();
  const { role, lembagaAuth } = useSelector((state) => state.auth);
  const { listRincian } = useSelector((state) => state.program);
  const { loadingAntrian, msgAntrian } = useSelector((state) => state.antrian);
  const [selectedLembagaApakah, setSelectedLembagaApakah] = useState({
    value: "-",
    label: "SEMUA",
  });
  const [selectedAnggaran, setSelectedAnggaran] = useState([]);
  const [show, setshow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const head = [
    "No",
    "No Kegiatan",
    "Lembaga",
    "Program",
    "Komponen",
    "Kegiatan",
    "Status",
    "Uraian",
    "Total Anggaran",
    "Total Realisasi",
    "Pilih",
    "Input Nilai",
  ];
  const handleSelectAnggaran = (e) => {
    if (
      !selectedAnggaran.includes(
        selectedAnggaran?.find((x) => x?.activity_id === e?.uuid)
      )
    ) {
      setSelectedAnggaran([
        ...selectedAnggaran,
        {
          activity_id: e.uuid,
          amount: "",
          accounting_year: e.tahun_ajar,
          month_index: e.sampai,
          sharing_program: e.sharing_program,
        },
      ]);
    } else {
      setSelectedAnggaran(
        selectedAnggaran.filter((r) => r.activity_id !== e.uuid)
      );
    }
  };
  const handleAmount = (value, selected) => {
    setSelectedAnggaran(
      selectedAnggaran.map((e) => {
        if (selected?.uuid === e.activity_id) {
          let money = convertToRupiah(value);
          let toIdr = formatRupiah(money, "");
          return {
            activity_id: selected.uuid,
            amount: toIdr,
            accounting_year: selected.tahun_ajar,
            month_index: selected.sampai,
            sharing_program: selected.sharing_program,
          };
        } else {
          return e;
        }
      })
    );
  };
  useEffect(() => {
    if (role === "admin-lembaga") {
      setSelectedLembagaApakah({
        value: lembagaApakah?.find((e) => e.label === lembagaAuth)?.value,
        label: lembagaApakah?.find((e) => e.label === lembagaAuth)?.label,
      });
    }
  }, [role, lembagaAuth, lembagaApakah]);
  useEffect(() => {
    if (selectedLembagaApakah?.value !== null) {
      dispatch(
        allRincianByLembaga({
          no_lembaga: selectedLembagaApakah?.value,
          status: "Disetujui",
        })
      );
      setSelectedAnggaran([]);
    } else {
      dispatch(
        allRincianByLembaga({
          no_lembaga: selectedLembagaApakah?.value,
          status: "Disetujui",
        })
      );
      setSelectedAnggaran([]);
    }
  }, [selectedLembagaApakah.value, showMessage]);
  const [sharingProgram, setSharingProgram] = useState({});
  const { allSharingProgram } = useSelector((state) => state.sharingProgram);
  const savePengajuan = () => {
    let anggaran = [];
    for (let i of selectedAnggaran) {
      let newAnggaran = {};
      newAnggaran.accounting_year = i.accounting_year;
      newAnggaran.amount = convertToRupiah(i.amount);
      newAnggaran.activity_id = i.activity_id;
      newAnggaran.month_index = i.month_index;
      newAnggaran.sharing_program = i.sharing_program;
      anggaran.push(newAnggaran);
    }
    dispatch(
      postAntrian({
        activity: anggaran,
        sharing_program_id: sharingProgram.value,
      })
    );
    setshow(false);
    setShowMessage(true);
  };
  useEffect(() => {
    dispatch(getAllSharingProgram());
  }, []);
  const sharingProgramOptions = allSharingProgram?.map((data) => {
    return { value: data.uuid, label: data.name };
  });
  return (
    <div>
      <Loader show={loadingAntrian} />
      <SnackBar
        show={showMessage}
        toggle={setShowMessage}
        msg={msgAntrian?.length > 0 ? "Anggaran melebihi batas" : "Succes"}
      />
      <HeaderUi titleHeader="Pencairan Anggaran" />
      <FormPengajuan
        show={show}
        close={() => setshow(!show)}
        submit={savePengajuan}
      >
        {selectedAnggaran?.find((data) => data.sharing_program) ? (
          <Selector
            title="Program Bersama"
            value={sharingProgram}
            onChange={(val) => setSharingProgram(val)}
            options={sharingProgramOptions}
          />
        ) : (
          <></>
        )}
      </FormPengajuan>
      <div className="m-5 shadow-lg p-5 flex flex-col gap-5">
        <div className="flex justify-between">
          {role === "admin-lembaga" ? (
            <></>
          ) : (
            <Selector
              title="Lembaga"
              options={lembagaApakah}
              value={selectedLembagaApakah}
              onChange={(e) => setSelectedLembagaApakah(e)}
            />
          )}
          <div
            className={`mt-6 ${
              selectedAnggaran.length > 0 ? "block" : "hidden"
            }`}
          >
            <Button title="Isi " click={() => setshow(!show)} />
          </div>
        </div>
        <Table head={head}>
          {listRincian?.map((e, i) => (
            <tr key={i} className={`bg-white border-b hover:bg-gray-100 `}>
              <td className="px-6 py-3">{i + 1}</td>
              <td className="px-6 py-3">{e.no_kegiatan}</td>
              <td className="px-6 py-3">
                {
                  e.list_kegiatan?.list_komponen?.list_program?.list_lembaga
                    ?.nama_lembaga
                }
              </td>
              <td className="px-6 py-3">
                {e.list_kegiatan?.list_komponen?.list_program?.item_program}
              </td>
              <td className="px-6 py-3">
                {e.list_kegiatan?.list_komponen?.item_komponen}
              </td>
              <td className="px-6 py-3">{e.list_kegiatan?.item_kegiatan}</td>
              <td className="px-6 py-3">
                {e.list_kegiatan?.status?.toUpperCase()}
              </td>
              <td className="px-6 py-3">{e.uraian}</td>
              <td className="px-6 py-3">{e.total ? currency(e.total) : 0}</td>
              <td className="px-6 py-3">
                {e.total_realisasi ? currency(e.total_realisasi) : currency(0)}
              </td>
              <td className="px-6 py-3">
                {e.total_realisasi === e.total ? (
                  <h1>-</h1>
                ) : (
                  <input
                    type="checkbox"
                    checked={selectedAnggaran.includes(
                      selectedAnggaran?.find((x) => x?.activity_id === e?.uuid)
                    )}
                    onClick={() => handleSelectAnggaran(e)}
                    readOnly
                  />
                )}
              </td>
              <td className="px-6 py-3">
                {selectedAnggaran.includes(
                  selectedAnggaran?.find((x) => x?.activity_id === e?.uuid)
                ) ? (
                  <input
                  className="px-2 py-1"
                    value={
                      selectedAnggaran?.find((x) => x?.activity_id === e?.uuid)
                        ?.amount || ""
                    }
                    onChange={(x) => handleAmount(x?.target?.value, e)}
                  />
                ) : (
                  <h1>-</h1>
                )}
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
