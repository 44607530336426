import './App.css';
import { Routes,Route, Navigate } from 'react-router-dom';
import Sidebar from './component/Sidebar';
import Dashboard from './UI/Dashboard';
import Kegiatan from './UI/Kegiatan';
import Login from './UI/Login';
import { useSelector } from 'react-redux';
import TableAdmin from './UI/TableAdmin';
import StatusAnggaran from './UI/StatusAnggaran';
import Pendapatan from './UI/Pendapatan';
import Rincian from './UI/Rincian';
import PencairanAnggaran from './UI/PencairanAnggaran';
import SharingProgram from './UI/SharingProgram';
import NewKegiatan from './UI/NewKegiatan';

function App() {
  const {token,role,super_admin,generalUser} = useSelector((state)=>state.auth)
  const eksekutif = 'eksekutif'
  const adminLembaga = 'admin-lembaga'
  const admin = 'bendahara'
  return (
      <Routes>
        <Route path='/' element={token !== "" ? <Navigate to={"/home/dashboard"} replace/> :  <Login/>}/>
        <Route path='/home' element={token !== "" ? <Sidebar/> : <Navigate to={"/"} replace />}>
          <Route path='/home/dashboard' element={<Dashboard/>}/>
          <Route path='/home/kegiatan' element={role !== eksekutif ? <Kegiatan/> : <Navigate to={"/home/dashboard"} replace/>}/>
          <Route path='/home/perencanaan-awal-tahun' element={role !== eksekutif ? <NewKegiatan/> : <Navigate to={"/home/dashboard"} replace/>}/>
          <Route path='/home/admin' element={super_admin || generalUser ? <TableAdmin/> : <Navigate to={"/home/dashboard"} replace/>}/>
          <Route path='/home/pendapatan' element={role !== eksekutif ? <Pendapatan/> : <Navigate to={"/home/dashboard"} replace/>}/>
          <Route path='/home/status-anggaran' element={role === eksekutif ? <StatusAnggaran/> : <Navigate to={"/home/dashboard"} replace/>}/>
          <Route path='/home/pencairan-anggaran' element={role === adminLembaga ?  <PencairanAnggaran/> : <Navigate to={"/home/dashboard"} replace/>}/>
          {/* <Route path='/home/rincian_kegiatan/:no_kegiatan/:no_sub_kegiatan' element={<Rincian/>}/> */}
          <Route path='/home/program-bersama' element={role === admin ? <SharingProgram/> : <Navigate to={"/home/dashboard"} replace/>}/>
        </Route>
      </Routes>
  );
}

export default App;
