import { createSlice } from "@reduxjs/toolkit";
import { postAntrian } from "../actions/antrian";


export const AntrianStore = createSlice({
    name:'antrian',
    initialState:{
        allAntrian:[],
        oneAntrian:{},
        loadingAntrian:false,
        msgAntrian:[]
    },
    extraReducers:builder=>{
        builder
        .addCase(postAntrian.pending,(state)=>{
            state.loadingAntrian = true
        })
        .addCase(postAntrian.fulfilled,(state,action)=>{
            state.loadingAntrian = false
            state.msgAntrian = action.payload?.msg
        })
        .addCase(postAntrian.rejected,(state,action)=>{
            state.loadingAntrian = false
            state.msgAntrian = action.payload?.msg
        })
    }
})

export default AntrianStore.reducer