// import axios from "axios";
import api from "./http"


// export const newApi = axios.create({
//     baseURL:"http://localhost:9191"
// })


const ApiAntrian = {
    post:(data)=>{
        return api.post('/disbursement-of-fund',data)
    }
}

export default ApiAntrian